import React from 'react';

import { StyledUserBookListWrapper } from './style';
import CarList from '../CarList';
import AddressList from '../AddressList';

type UserBookListType = {
  isDelivery: boolean;
  onItemClick?: () => void;
  onItemClicked?: () => void;
};

const UserBookList: React.FC<UserBookListType> = ({ isDelivery, onItemClick, onItemClicked }) => (
  <StyledUserBookListWrapper>
    {isDelivery ? (
      <AddressList onBeforeSelectAddress={onItemClick} onAfterSelectAddress={onItemClicked} />
    ) : (
      <CarList onCarClick={onItemClick} />
    )}
  </StyledUserBookListWrapper>
);

export default UserBookList;
